import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { injectSheet } from '@talpanetwork/talpa-publishing-theme/theming/injectSheet';
import { gigyaScreenSets, isAuthorized, showScreenSet } from '@imports';

import { ThemeProps } from '@theme/ThemeProps.model';
import { ArticleModel, IAuthAction, IStoreState } from '@type/index';

import styles from './Authenticator.styles';

interface Props {
  auth: {
    gigya: any;
  };
  data?: ArticleModel;
  children(approve: boolean): ReactElement;
}
type AuthenticatorProps = ThemeProps & Props;

type AuthenticatorState = {
  approve: boolean;
};

const loggedIn = (auth: any): boolean => Boolean(auth?.gigya);

const showLogin = (info: IAuthAction): void => {
  const user = info.payload?.gigya;
  if (!user || user.errorMessage) {
    gigyaScreenSets.showLogin();
  } else {
    gigyaScreenSets.showProfile();
  }
  showScreenSet(process.env.NEXT_PUBLIC_SCREENSET_CONTAINER);
};

const showRegistration = (info: IAuthAction): void => {
  const user = info.payload?.gigya;
  if (!user || user.errorMessage) {
    gigyaScreenSets.showRegistration();
  } else {
    gigyaScreenSets.showProfile();
  }
  showScreenSet(process.env.NEXT_PUBLIC_SCREENSET_CONTAINER);
};

const AuthenticatorComponent: React.FC<AuthenticatorProps> = (
  props
): ReactElement | null => {
  const { data, children } = props;
  const auth = useSelector((state: IStoreState) => state.auth);
  const [authenticatorState, setAuthenticatorState] =
    useState<AuthenticatorState>({ approve: false });
  const approve = loggedIn(auth) || !isAuthorized(data);

  useEffect(() => {
    setAuthenticatorState({ approve });
  }, [approve]);

  return children(authenticatorState.approve);
};

const Authenticator = injectSheet(styles)(AuthenticatorComponent);

export { Authenticator as default, showLogin, showRegistration };
