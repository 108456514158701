const LayoutStyles = (theme) => ({
  layoutHolder: {
    position: 'relative',
    width: '100%'
  },

  navigationHolder: {
    top: 0,
    width: '100%',
    height: theme.components.Navigation.mainHeader.height,
    zIndex: 200,
    position: 'fixed',
    boxShadow: theme.shadows.large,
    backgroundImage: theme.palette.gradient.second
  },

  backdrop: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: theme.palette.default.black,
    opacity: 0,
    zIndex: 0,
    transition: 'opacity .4s ease-out, z-index 1s ease-out'
  },

  showBackdrop: {
    opacity: 0.5,
    zIndex: 201,
    transition: 'opacity .4s ease-out'
  },

  navigationWrapper: {
    position: 'relative',
    width: '100%',
    display: 'inline-block',
    overflow: 'hidden',

    '&>div': {
      display: 'flex'
    }
  },

  searchPageNavigationWrapper: {
    boxShadow: theme.shadows.large
  },

  contentHolder: {
    paddingLeft: '375px',
    position: 'static',
    zIndex: theme.zIndex.overlay,
    paddingTop: theme.components.Navigation?.mainHeader?.height
  },

  votingContentHolder: {
    paddingLeft: 0,
    paddingTop: 0
  },

  appContentHolder: {
    paddingTop: 80
  },

  contentInner: {
    position: 'relative',
    width: '100%',
    transition: 'all 0.4s ease-out'
  },

  hasNavItems: {
    transition: 'all 0.288s linear'
  },

  appClass: {
    padding: 0
  },

  [theme.breakpoints.down('lg')]: {
    contentHolder: {
      paddingLeft: '336px'
    },
    votingContentHolder: {
      paddingLeft: 0
    }
  },

  [theme.breakpoints.down('md')]: {
    navigationWrapper: {
      '&>div': {
        flexWrap: 'wrap'
      }
    },
    navigationHolder: {
      height: theme.components.Navigation.mainHeaderMd.height
    },
    contentHolder: {
      paddingLeft: 0,
      paddingTop: theme.components.Navigation?.mainHeaderMd?.height
    },
    votingContentHolder: {
      paddingLeft: 0,
      paddingTop: 0
    },
    hasNavItems: {
      paddingTop: theme.components.Navigation?.navItemsTabletWrapperMd?.height
    },
    appContentHolder: {
      paddingTop: 0
    }
  },

  [theme.breakpoints.down('sm')]: {
    hasNavItems: {
      paddingTop: theme.components.Navigation?.navItemsTabletWrapperSm?.height
    }
  }
});

export default LayoutStyles;
